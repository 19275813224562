
import { defineComponent, onMounted } from "vue";
//import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
//import TableWidget5 from "@/components/widgets/tables/Widget5.vue";
//import ListWidget1 from "@/components/widgets/lists/Widget1.vue";
//import ListWidget2 from "@/components/widgets/lists/Widget2.vue";
import ListTotalCustomers from "@/components/widgets/lists/TotalCustomers.vue";
import ListTotalSigfoxDevices from "@/components/widgets/lists/TotalSigfoxDevices.vue";
import ListTotalLoraDevices from "@/components/widgets/lists/TotalLoraDevices.vue";
import ListTotalNbiotDevices from "@/components/widgets/lists/TotalNbiotDevices.vue";
import ListTotalMessages from "@/components/widgets/lists/TotalMessages.vue";
import ListTotalErrors from "@/components/widgets/lists/TotalErrors.vue";
import ListTotalPlatformUsers from "@/components/widgets/lists/TotalPlatformUsers.vue";
//import ListWidget3 from "@/components/widgets/lists/Widget3.vue";
import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
import ListTotalBinsonPlatform from "@/components/widgets/lists/TotalBinsonPlatform.vue";
//import ListWidget6 from "@/components/widgets/lists/Widget6.vue";

//import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
    //TableWidget9,
    //TableWidget5,
    //ListWidget1,
    //ListWidget2,
    //ListWidget3,
    ListWidget5,
    ListTotalBinsonPlatform,
    ListTotalCustomers,
    ListTotalSigfoxDevices,
    ListTotalLoraDevices,
    ListTotalNbiotDevices,
    ListTotalMessages,
    ListTotalErrors,
    ListTotalPlatformUsers,
    //ListWidget6,
    //MixedWidget5,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
  },
});
