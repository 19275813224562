
import { defineComponent } from "vue";
import Card2 from "@/components/cards/Card2.vue";

export default defineComponent({
  name: "kt-widget-5",
  props: {
    widgetClasses: String,
  },
  components: {
    Card2,
  },
});
